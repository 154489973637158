<template>
  <InvoicesTemplate
    :apiActions="apiActions"
    getInvoicesUrl="invoices/list"
    :invoiceStatusesList="invoiceStatusesList" />
</template>
<script setup>
import InvoicesTemplate from "@/components/invoices/InvoicesTemplate";

const apiActions = {
  sendRequest: "sendInvoicesRequest",
  downloadRequest: "downloadInvoicesRequest",
  syncRequests: {
    default: "syncInvoicesRequest",
    subType: true,
  },
};
const invoiceStatusesList = [
  { title: "All", value: "" },
  { title: "Open", value: "open" },
  { title: "Paid", value: "paid" },
  { title: "Draft", value: "draft" },
  { title: "Uncollectible", value: "uncollectible" },
  { title: "Void", value: "void" },
];
</script>
